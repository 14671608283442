@charset "UTF-8";

@import "functions";

//////////////////////////////////////////////////
// GENERAL SETTING
//////////////////////////////////////////////////

$zaux-font-size-base-px: 16px;
$zaux-app-max-width: 1800px;

//////////////////////////////////////////////////
// STATES
//////////////////////////////////////////////////

$zaux-system-classname-base: "zaux";
$zaux-system-classname-components-loaded: "#{$zaux-system-classname-base}-components-loaded";

//////////////////////////////////////////////////
// EASINGS
// @see https://matthewlein.com/tools/ceaser
//////////////////////////////////////////////////

$zaux-ease-outcubic: cubic-bezier(0.215, 0.61, 0.355, 1); //easeOutCubic

//////////////////////////////////////////////////
// TRANSITIONS
//////////////////////////////////////////////////

$zaux-transition-0: 0.3s $zaux-ease-outcubic;
$zaux-transition-1: 0.6s $zaux-ease-outcubic;
$zaux-transition-2: 1.2s $zaux-ease-outcubic;
$zaux-transition-3: 2.4s $zaux-ease-outcubic;
$zaux-transition-4: 3.4s $zaux-ease-outcubic;
$zaux-transition-5: 4.4s $zaux-ease-outcubic;

//////////////////////////////////////////////////
// COLOR SETS
//////////////////////////////////////////////////

// Custom sets read from JSON (build using Node and Gulp)
// @see src/data/colors.json
@import "../_generated/colors";

//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////

$zaux-font1: "apparat-light", Arial, sans-serif;
$zaux-font2: "apparat", Arial, sans-serif;

$zaux-typo-fw-thin: 100;
$zaux-typo-fw-extralight: 200;
$zaux-typo-fw-light: 300;
$zaux-typo-fw-regular: 400;
$zaux-typo-fw-medium: 500;
$zaux-typo-fw-semibold: 600;
$zaux-typo-fw-bold: 700;
$zaux-typo-fw-extrabold: 800;
$zaux-typo-fw-black: 900;
$zaux-typo-fw-extrablack: 950;

//////////////////////////////////////////////////
// BOOTSTRAP OVERRIDE
// @see https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss
//////////////////////////////////////////////////

// Flags
$enable-reduced-motion: false;
$enable-smooth-scroll: false;
$enable-rfs: false;
$enable-negative-margins: true;

$font-family-sans-serif: $zaux-font1;
$font-family-monospace: "Courier New", monospace !default;
$font-weight-base: $zaux-typo-fw-regular;

$headings-margin-bottom: 0;
$headings-font-weight: $zaux-typo-fw-regular;
$headings-line-height: 1;

$body-bg: $zaux-color-default-white;
$body-color: $zaux-color-set1-onyx;
$link-color: $zaux-color-default-blue;

$hr-margin-y: 0;
$hr-height: 0;
$hr-opacity: 1;
$border-color: $zaux-color-set1-onyx;
// $hr-color:inherit !default;

// Removing default colors, sizes
$colors: ();
$theme-colors: ();
$grays: ();
$font-sizes: (
  inherit: inherit,
);
$display-font-sizes: ();
$link-decoration: none;
$paragraph-margin-bottom: 0;

$grid-breakpoints: (
  "xs": 0,
 // "sm": 576px,
 // "md": 768px,
  "lg": 992px,
  "xl": 1280px,
  "xxl": 1580px,
  "xlm": 1601px,
  // "xxl": $zaux-app-max-width,
);

$spacer: 1rem !default; // 1rem = 16px

$border-radius-lg: $spacer * 0.5;

$spacers: (
  "0": 0,
  // 4px
  // "1": $spacer * .25,
  // 8px
  "2": $spacer * 0.5,
  // 16px
  "3": $spacer,
  // 24px
  "4": $spacer * 1.5,
  // 32px
  "5": $spacer * 2,
  // 48px
  "6": $spacer * 3,
  // 64px
  "7": $spacer * 4,
  // 80px
  "8": $spacer * 5,
  // 96px
  "9": $spacer * 6,
  // 112px
  "10": $spacer * 7,
  // 128px
  "11": $spacer * 8,
  // 144px
  "12": $spacer * 9,
  // 160px
  "13": $spacer * 10,
);

$grid-gutter-width: $spacer; // 24px; // From Bootstrap

$gutters: (
  "0": 0,
  "4": $spacer * 1.5,
);

//////////////////////////////////////////////////
// Z-INDEX
//////////////////////////////////////////////////

$zaux-zindex-header: 30;
$zaux-zindex-sidebar: $zaux-zindex-header + 20;
$zaux-zindex-contents: 20;

//////////////////////////////////////////////////
// COMPONENTS SETTING
//////////////////////////////////////////////////

// Base
$component-prefix: "c-" !default;

// Typo
// $zaux-typo-breakpoints-map: ("xs", "sm", "md", "lg", "xl");
$zaux-typo-breakpoints-map: ("lg", "xl","xlm");
$zaux-typo-fs-map: (
  // Disabled
  // "1":
  // (
  //   "fs": rem(8px),
  //   "lh": 1
  // ),
  "1":
    (
      "fs": rem(10px),
      "lh": rem(12px),
    ),
  "2": (
    "fs": rem(13px),
    "lh": rem(15px),
  ),
  "3": (
    "fs": rem(14px),
    "lh": rem(20px),
  ),
  "6": (
    "fs": rem(16px),
    "lh": rem(20px),
  ),
  "8": (
    "fs": rem(20px),
    "lh": 1,
  ),
  "10": (
    "fs": rem(32px),
    "lh": 1,
  ),
  "13": (
    "fs": rem(50px),
    "lh": 1,
  ),
  "16": (
    "fs": rem(80px),
    "lh": 1,
  )
);

// Sidebar
$zaux-sidebar-w: 70px;
$zaux-sidebar-active-w: 340px;

// Btn
$zaux-hover-darken-percentage: 5% !default;
$zaux-hover-lighten-percentage: 5% !default;
$zaux-active-lighten-percentage: 10% !default;
$zaux-active-darken-percentage: 10% !default;

//////////////////////////////////////////////////
// MISC
//////////////////////////////////////////////////

$hamburger-types: (arrow, slider);

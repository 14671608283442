@charset "UTF-8";

$zaux-color-default-white: #FFFFFF;
$zaux-color-default-black: #333333;
$zaux-color-default-red: #d9534f;
$zaux-color-default-green: #5cb85c;
$zaux-color-default-blue: #337ab7;
$zaux-color-default-magenta: #FF00FF;
$zaux-color-default-orange: #f0ad4e;

$zaux-color-set-default: (
	"white": #FFFFFF,
	"black": #333333,
	"red": #d9534f,
	"green": #5cb85c,
	"blue": #337ab7,
	"magenta": #FF00FF,
	"orange": #f0ad4e,
);

$zaux-color-app-theme: #343b43;

$zaux-color-set-app: (
	"theme": #343b43,
);

$zaux-color-set1-onyx: #343b43;
$zaux-color-set1-grey1: #999CA0;
$zaux-color-set1-azurewhite: #EFF4F8;
$zaux-color-set1-beaublue: #C5D6EF;
$zaux-color-set1-darkblue: #003567;
$zaux-color-set1-darkgrayish: #333f4c;
$zaux-color-set1-azure1: #CAE1F1;
$zaux-color-set1-azure1_to_darkblue: linear-gradient(to top, #003567, #CAE1F1);

$zaux-color-set-set1: (
	"onyx": #343b43,
	"grey1": #999CA0,
	"azurewhite": #EFF4F8,
	"beaublue": #C5D6EF,
	"darkblue": #003567,
	"darkgrayish": #333f4c,
	"azure1": #CAE1F1,
	"azure1_to_darkblue": linear-gradient(to top, #003567, #CAE1F1),
);


/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
*/
@import "hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  &--style1 {
    $hamburger-width: 20px;
    $hamburger-height: 2px;

    .hamburger-box {
      width: $hamburger-width;
      height: $hamburger-height;
      .hamburger-inner {
        height: $hamburger-height;
        width: $hamburger-width;
        &:after,
        &:before {
          height: $hamburger-height;
          width: $hamburger-width;
        }
      }
    }

    // For 20px icon
    &.is-active {
      .hamburger-inner {
        &:before {
          transform: translate3d(-7px, 5.5px, 0) rotate(-45deg) scale(0.7, 1);
        }
        &:after {
          transform: translate3d(-7px, -5.5px, 0) rotate(45deg) scale(0.7, 1);
        }
      }
    }
  }
  &--style2 {
    $hamburger-width: 27px;
    $hamburger-height: 2px;

    .hamburger-box {
      width: $hamburger-width;
      height: $hamburger-height;
      .hamburger-inner {
        height: $hamburger-height;
        width: $hamburger-width;
        &:after,
        &:before {
          height: $hamburger-height;
          width: $hamburger-width;
        }
      }
    }
  }
}
